<template>
  <div class="alfa-155" v-if="!$store.state.loading">
    <div class="zoom-image-container" id="alfa_155" style="margin-top: 70px" data-aos="fade-up" data-aos-duration="1000">
      <img :src="images[0]" alt="CarboBrake Alfa 155"/>
    </div>

    <div class="section">
      <h1 data-aos="zoom-in" data-aos-duration="1000" v-html="$t('projects-section4-main-title')"></h1>
      <div
          class="section-title side"
          data-aos="fade-right"
          data-aos-duration="1000"
          v-html="$t('projects-section4-title')"
      ></div>
      <div
          class="section-paragraph side"
          data-aos="fade-left"
          data-aos-duration="1000"
          v-html="$t('projects-section4-paragraph')"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      images: [
        require('../assets/alfa_155.jpg'),
      ]
    }
  },
  mounted() {
    //LOAD ALL IMAGES IN PAGE
    this.$store.dispatch('LOAD_IMAGES', this.images).then(() => {
      this.$nextTick(() => {
        setTimeout(() => {
          this.$store.commit('setFooterVisible')

          let event
          if (typeof Event === 'function') {
            event = new Event('resize')
          } else {
            //IE
            event = document.createEvent('Event')
            event.initEvent('resize', true, true)
          }
          window.dispatchEvent(event)
        }, 1100)
      })
    })
  }
}
</script>

<style lang="scss"></style>